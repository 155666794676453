import axios from 'axios';

// const baseURL = 'http://localhost:5000';
const baseURL = 'https://api.naesview.com';

const url = baseURL + '/post';
const API = axios.create({ baseURL: baseURL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  return req;
});

export const fetchPosts = () => API.get(url); 
export const fetchPost = (id) => API.get(`${url}/${id}`); 
export const createPost = (newPost) => API.post(url, newPost);
export const updatePost = (id, updatedPost) => API.patch(`${url}/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`${url}/${id}`);
export const reviewPost = (id, review) => API.patch(`${url}/${id}/reviewPost`, review);
export const clearReviews = (id) => API.delete(`${url}/${id}/reviews`);

export const signInGoogle = (loginData) => API.post('/user/googlesignin', loginData);
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const verifyEmail = (formData) => API.post('/user/verify-email', formData);
export const recovery = (formData) => API.post('/user/recovery', formData);
export const resetPassword = (formData) => API.post('/user/reset-password', formData);