import * as nifti from 'nifti-reader-js';
import * as cornerstone from '@cornerstonejs/core';
import dicomParser from 'dicom-parser';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { mat3 } from 'gl-matrix';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';

import { BlobReader, BlobWriter, ZipReader } from '@zip.js/zip.js';

var zipFile = null;
export function setZipFile(file) {
  zipFile = file;
}

export function loader(volumeId) {
  const promise = new Promise((resolve, reject) => {
    try {
      const path = volumeId.substring(volumeId.indexOf(":") + 3)
      fetchNIFTI(path).then(arrayBuffer => {
        if (nifti.isCompressed(arrayBuffer)) {
          arrayBuffer = nifti.decompress(arrayBuffer);
        }
        const header = nifti.readHeader(arrayBuffer);
        const imageData = nifti.readImage(header, arrayBuffer);
        var volume = getNIFTIVolume(header, imageData, volumeId)
        resolve(volume);
      })
    } 
    catch (error)
    {
      reject(new Error(error));
    }
  });
  return {
    promise,
  };
}

export function getNIFTIVolume(header, niftiImage, volumeId) {
  var cols = header.dims[1];
  var rows = header.dims[2];

  var typedData;

  if (header.datatypeCode === nifti.NIFTI1.TYPE_UINT8) {
      typedData = new Uint8Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_INT16) {
      typedData = new Int16Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_INT32) {
      typedData = new Int32Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_FLOAT32) {
      typedData = new Float32Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_FLOAT64) {
      typedData = new Float64Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_INT8) {
      typedData = new Int8Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_UINT16) {
      typedData = new Uint16Array(niftiImage);
  } else if (header.datatypeCode === nifti.NIFTI1.TYPE_UINT32) {
      typedData = new Uint32Array(niftiImage);
  } else {
      return;
  }

  var max = 0.0;
  for (let i = 0; i < typedData.length; i++) {
    if (typedData[i] > max) {
      max = typedData[i];
    }
  }

  const spacing = [header.pixDims[1], header.pixDims[2], header.pixDims[3]];
  const numOfVoxels = rows * cols * header.dims[3];
  const dimensions = [ cols, rows, header.dims[3] ];
  const direction = mat3.fromValues(1, 0, 0, 0, 1, 0, 0, 0, 1); // mat3.fromValues( header.quatern_b, header.quatern_c, header.quatern_d , header.quatern_b, header.quatern_c, header.quatern_d, header.quatern_b, header.quatern_c, header.quatern_d)
  const origin = [ header.affine[3][0], header.affine[3][1], header.affine[3][2] ];

  const imgData = vtkImageData.newInstance();
  const scalarArray = vtkDataArray.newInstance({
    name: 'Pixels',
    numberOfComponents: 1,
    values: typedData,
  });
  
  imgData.setDimensions(dimensions);
  imgData.setSpacing(spacing);
  imgData.setDirection(direction);
  imgData.setOrigin(origin);
  imgData.getPointData().setScalars(scalarArray);

  const volume = new cornerstone.ImageVolume({
    imageIds: [], // imageIds,
    scalarData: typedData,
    dimensions: dimensions,
    spacing: spacing,
    origin: origin,
    direction: direction,
    volumeId: volumeId,
    referencedVolumeId: null,
    sizeInBytes: numOfVoxels * header.numBitsPerVoxel,
    imageData: imgData,
    metadata: {
      imagePixelModule: {
          pixelRepresentation: 1,
          bitsAllocated: 8,
          sizeInBytes: 1,
      },
      PhotometricInterpretation : 'MONOCHROME2',
      generalSeriesModule: {
          modality: 'CR',
      },
      imagePlaneModule: {
          imagePositionPatient: 0,
      }
    },
    referencedImageIds: null,
    additionalDetails: null,
  })

  return volume;
}


export function fetchNIFTI(filePath) {
  return new Promise((resolve, reject) => {
    const blobReader = new BlobReader(zipFile);
    const zipReader = new ZipReader(blobReader);
    zipReader.getEntries().then(entries => {
      for (const entry of entries) {
        if (entry.filename.startsWith(filePath) && !entry.directory) {
          entry.getData(new BlobWriter()).then(function(blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
          }).catch(reject);
        }
      }
    }
    )
  })
}
