import React, { useState } from "react";
import { Button, Grid, Typography, Container } from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InputField from "../../InputField/InputField";
import { StyledPaper, StyledAvatar, StyledForm, StyledSubmit } from "./styles";

import { recovery } from "../../../actions/auth";

const initialState = { firstName: "", lastName: "", email: "", password: "", confirmPassword: "" };

const RecoveryPage = () => {
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    toast("If a user with this email exists, a password reset email will be sent to them", { type: "info" });
    
    dispatch(recovery(formData, navigate));
  }

  const handleChange = (e) => { 
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <StyledPaper elevation={3}>
        <StyledAvatar><LockResetIcon /></StyledAvatar>
        <Typography variant="h5"> Reset password </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <div>
            <Grid container spacing={2}>
              <InputField name="email" value={formData.email}  label="Email Address" type="email" handleChange={handleChange} />
            </Grid>
          </div>
          <StyledSubmit><Button type="submit" fullWidth variant="contained" color="primary">Reset password</Button></StyledSubmit>
        </StyledForm>
      </StyledPaper>
    </Container>
  );
}

export default RecoveryPage;