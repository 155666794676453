import dicomParser from 'dicom-parser';
import * as cornerstone from '@cornerstonejs/core';
import { DICOMLoader } from '@cornerstonejs/dicom-image-loader';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { mat3 } from 'gl-matrix';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import { BlobReader, BlobWriter, ZipReader } from '@zip.js/zip.js';

var zipFile = null;
export function setZipFile(file) {
  zipFile = file;
}

export function loader(volumeId) {
  const promise = new Promise((resolve, reject) => {
    try {
      const path = volumeId.substring(volumeId.indexOf(":") + 3)
      fetchDICOMfolder(path, volumeId).then(volume => {
        resolve(volume)
      })

    } 
    catch (error)
    {
      reject(new Error(error));
    }
  });
  return {
    promise,
  };
}

function blobToArrayBuffer(imgUrl) {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        resolve(imageData);
      };
      img.onerror = function() {
        reject(new Error('Failed to load image'));
      };
      img.src = imgUrl;
  });
}

export function fetchDICOMfolder(filePath, volumeId) {
  return new Promise((resolve, reject) => {
    const blobReader = new BlobReader(zipFile);
    const zipReader = new ZipReader(blobReader);
    var promises = [];
    zipReader.getEntries().then(entries => {
      for (const entry of entries) {       
        if (entry.filename.startsWith(filePath) && !entry.directory) {   
          promises.push(new Promise((resolve, reject) => {
            entry.getData(new BlobWriter()).then(function(data) {
              const reader = new FileReader();
              reader.onload = (event) => {
                var arrayBuffer = event.target.result;
                var byteArray = new Uint8Array(arrayBuffer);

                const options = { TransferSyntaxUID: '1.2.840.10008.1.2' };
                var dataSet = dicomParser.parseDicom(byteArray, options);
    
                resolve(dataSet)
              };
              reader.onerror = reject;
              reader.readAsArrayBuffer(data);
            })
          }).catch(reject));
        }
      }
      Promise.all(promises).then(imageDataArray => {
        const volume = stackImagesInto3DArray(volumeId, imageDataArray);
        resolve(volume);
      })
      .catch(error => {
        reject(new Error('Failed to process images: ' + error.message));
      });
    });

  })
}

export const stackImagesInto3DArray = (volumeId, imageDataArray) => {
  if (imageDataArray.length === 0) {
    throw new Error('No image data available to stack.');
  }
  
  const width = imageDataArray[0].int16("x00280010")
  const height = imageDataArray[0].int16("x00280011")
  const depth = imageDataArray.length;
  const pixelCount = width * height * depth;

  const volume = new Uint8ClampedArray(width * height * depth);
  imageDataArray.forEach((imageData, z) => {
    const currentWidth = imageData.int16("x00280010")
    const currentHeight = imageData.int16("x00280011")
    if (currentWidth !== width || currentHeight !== height) {
      throw new Error('Image dimensions do not match.');
    }

    var pixelDataElement = imageData.elements.x7fe00010;
    var pixelData = new Uint16Array(imageData.byteArray.buffer, pixelDataElement.dataOffset, pixelDataElement.length / 2);
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelIndex = (y * width + x) * 1;
        const volumeIndex = (z * width * height + y * width + x) * 1;

        volume[volumeIndex] = pixelData[pixelIndex];       // R
      }
    }
  });

  const spacing = [ 1.0, 1.0, 1.0 ];
  const dimensions = [ width, height, depth ];
  const direction = mat3.fromValues(1, 0, 0, 0, 1, 0, 0, 0, 1);
  const origin = [ 0, 0, 0 ];

  const imgData = vtkImageData.newInstance();

  var vtkArray = vtkDataArray.newInstance({
    numberOfComponents: 1,
    values: volume,
  });

  imgData.setDimensions(dimensions);
  imgData.setSpacing(spacing);
  imgData.setDirection(direction);
  imgData.setOrigin(origin);
  imgData.getPointData().setScalars(vtkArray);


  return new cornerstone.ImageVolume({
    imageIds: [],
    scalarData: volume,
    dimensions: dimensions,
    spacing: spacing,
    origin: origin,
    direction: direction,
    volumeId: volumeId,
    referencedVolumeId: null,
    sizeInBytes: pixelCount * 8,
    imageData: imgData,
    metadata: {
      PhotometricInterpretation : 'rgb',
      generalSeriesModule: {
          modality: 'CR',
      },
      imagePlaneModule: {
          imagePositionPatient: 0,
      }
    },
    referencedImageIds: null,
    additionalDetails: null,
  })
};