import dicomParser from 'dicom-parser';
import * as cornerstone from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { mat3 } from 'gl-matrix';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import { BlobReader, BlobWriter, ZipReader } from '@zip.js/zip.js';

var zipFile = null;
export function setZipFile(file) {
  zipFile = file;
}

export function loader(volumeId) {
  const promise = new Promise((resolve, reject) => {
    try {
      const path = volumeId.substring(volumeId.indexOf(":") + 3)
      fetchImageStack(path, volumeId).then(volume => {
        resolve(volume)
      })

    } 
    catch (error)
    {
      reject(new Error(error));
    }
  });
  return {
    promise,
  };
}

function blobToArrayBuffer(imgUrl) {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        resolve(imageData);
      };
      img.onerror = function() {
        reject(new Error('Failed to load image'));
      };
      img.src = imgUrl;
  });
}

export function fetchImageStack(filePath, volumeId) {
  return new Promise((resolve, reject) => {
    const blobReader = new BlobReader(zipFile);
    const zipReader = new ZipReader(blobReader);
    var promises = [];
    zipReader.getEntries().then(entries => {
      for (const entry of entries) {
        if (entry.filename.startsWith(filePath) && !entry.directory) {
          promises.push(new Promise((resolve, reject) => {
            entry.getData(new BlobWriter()).then(function(data) {
            
              const reader = new FileReader();
              reader.onloadend = () => {
                const blob = new Blob([data], { type: 'image/png' });
                const url = URL.createObjectURL(blob);
                resolve(blobToArrayBuffer(url))
              };
              reader.onerror = reject;
              reader.readAsArrayBuffer(data);
            })
          }).catch(reject));
        }
      }
      Promise.all(promises).then(imageDataArray => {
        const volume = stackImagesInto3DArray(volumeId, imageDataArray);
        resolve(volume);
      })
      .catch(error => {
        reject(new Error('Failed to process images: ' + error.message));
      });
    });

  })
}



export const stackImagesInto3DArray = (volumeId, imageDataArray) => {
  if (imageDataArray.length === 0) {
    throw new Error('No image data available to stack.');
  }
  const width = imageDataArray[0].width;
  const height = imageDataArray[0].height;
  const depth = imageDataArray.length;
  const pixelCount = width * height * depth;

  const volume = new Uint8ClampedArray(width * height * depth);
  imageDataArray.forEach((imageData, z) => {
    if (imageData.width !== width || imageData.height !== height) {
      throw new Error('Image dimensions do not match.');
    }

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelIndex = (y * width + x) * 1;
        const volumeIndex = (z * width * height + y * width + x) * 1;

        volume[volumeIndex] = imageData.data[pixelIndex];       // R
        // volume[volumeIndex + 1] = imageData.data[pixelIndex + 1]; // G
        // volume[volumeIndex + 2] = imageData.data[pixelIndex + 2]; // B
        // volume[volumeIndex + 3] = imageData.data[pixelIndex + 3]; // A
      }
    }
  });

  const spacing = [ 1.0, 1.0, 1.0 ];
  const dimensions = [ width, height, depth ];
  const direction = mat3.fromValues(1, 0, 0, 0, 1, 0, 0, 0, 1);
  const origin = [ 0, 0, 0 ];

  const imgData = vtkImageData.newInstance();

  var vtkArray = vtkDataArray.newInstance({
    numberOfComponents: 1,
    values: volume,
  });

  imgData.setDimensions(dimensions);
  imgData.setSpacing(spacing);
  imgData.setDirection(direction);
  imgData.setOrigin(origin);
  imgData.getPointData().setScalars(vtkArray);


  return new cornerstone.ImageVolume({
    imageIds: [],
    scalarData: volume,
    dimensions: dimensions,
    spacing: spacing,
    origin: origin,
    direction: direction,
    volumeId: volumeId,
    referencedVolumeId: null,
    sizeInBytes: pixelCount * 8,
    imageData: imgData,
    metadata: {
      PhotometricInterpretation : 'rgb',
      generalSeriesModule: {
          modality: 'CR',
      },
      imagePlaneModule: {
          imagePositionPatient: 0,
      }
    },
    referencedImageIds: null,
    additionalDetails: null,
  })
};