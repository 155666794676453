import React from 'react';
import { Typography, Fade } from '@mui/material';
import { StyledCard } from './styles';

import CounterCard from './CounterCard/CounterCard';

const AboutUs = () => {

  return (
    <>
    <StyledCard>
      <Fade in={true} timeout={1000}>
        <div>
        <Typography variant="h5" gutterBottom>Welcome to naesView!</Typography>
        <Typography variant="h7" gutterBottom>This platform helps researchers and experts collaborate to review medical images and provide valuable feedback.</Typography><br/>
        <Typography variant="h7" gutterBottom>Our goal is to make communication easier to speed up the development and evaluation of advanced models in medical imaging.</Typography>
        </div>
      </Fade>
    </StyledCard>
    <CounterCard />
    <StyledCard>
      <Fade in={true} timeout={3000}>
        <div>
        <Typography variant="h5" gutterBottom>Easy setup.</Typography>
        <Typography variant="h7" gutterBottom>Create your custom page and invite others to start reviewing!</Typography>
        <Typography variant="h7" gutterBottom>A website for easy medical image evaluations.</Typography>
        </div>
      </Fade>
    </StyledCard>
    </>
  );
}

export default AboutUs;