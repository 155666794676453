import { RenderingEngine, Enums, Types } from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import * as cornerstone from '@cornerstonejs/core';
import * as cornerstoneAdapters from '@cornerstonejs/adapters';
import { init as csRenderInit } from '@cornerstonejs/core';
import { init as csToolsInit } from '@cornerstonejs/tools';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';

import * as naesNIFTIVolumeLoader from './naesNIFTIVolumeLoader';
import * as naesImageVolumeLoader from './naesImageVolumeLoader';
import * as naesDICOMVolumeLoader from './naesDICOMVolumeLoader';

const { ViewportType } = Enums;

const {
  PanTool,
  WindowLevelTool,
  SegmentationDisplayTool,
  BrushTool,
  StackScrollMouseWheelTool,
  ZoomTool,
  ToolGroupManager,
  Enums: csToolsEnums,
} = cornerstoneTools;

const { MouseBindings } = csToolsEnums;
const toolGroupId = 'myToolGroup';

function getMinMax(pixelData) {
  let min = pixelData[0];
  let max = pixelData[0];
  
  for (let i = 1; i < pixelData.length; i++) {
    if (pixelData[i])
    {
      if (pixelData[i] < min) 
      {
        min = pixelData[i];
      }
      if (pixelData[i] > max) 
      {
        max = pixelData[i];
      }
    }
  }
  return { min, max };
}

export default function setCtTransferFunctionForVolumeActor({ volumeActor }) {
  const array = volumeActor.getMapper().getInputData().getPointData().getScalars().getData()
  const { min: min, max: max } = getMinMax(array);
  
  volumeActor
    .getProperty()
    .getRGBTransferFunction(0)
    .setMappingRange(min, max)

  const ofun = vtkPiecewiseFunction.newInstance();
  ofun.addPoint(min, 0.0);
  ofun.addPoint(max, 1.0);

  // volumeActor.getProperty().setScalarOpacity(0, ofun);
}

export const initDemo = async function(useBrush=false) {
  await csToolsInit();
  await csRenderInit();

  cornerstone.volumeLoader.registerVolumeLoader('naesNIFTI', naesNIFTIVolumeLoader.loader);
  cornerstone.volumeLoader.registerVolumeLoader('naesDICOM', naesDICOMVolumeLoader.loader);
  cornerstone.volumeLoader.registerVolumeLoader('naesImage', naesImageVolumeLoader.loader);
  cornerstone.metaData.addProvider(customMetaDataProvider, 10000);
  cornerstone.setUseSharedArrayBuffer(false);

  try {
    cornerstoneTools.addTool(SegmentationDisplayTool);
    if (useBrush) {
     cornerstoneTools.addTool(BrushTool);
    } else {
      cornerstoneTools.addTool(WindowLevelTool);
    }
    cornerstoneTools.addTool(PanTool);
    cornerstoneTools.addTool(ZoomTool);
    cornerstoneTools.addTool(StackScrollMouseWheelTool);
  
  } catch (error) {
    // console.error(error);
  }

  if (ToolGroupManager.getToolGroup(toolGroupId)) ToolGroupManager.destroyToolGroup(toolGroupId);

  const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

  // Add tools to the tool group
  toolGroup.addTool(SegmentationDisplayTool.toolName);
  if (useBrush) {
    toolGroup.addTool(BrushTool.toolName);
  }
  else
  {
    toolGroup.addTool(WindowLevelTool.toolName);
  }
  
  toolGroup.addTool(PanTool.toolName);
  toolGroup.addTool(ZoomTool.toolName);
  toolGroup.addTool(StackScrollMouseWheelTool.toolName);

  // Set the initial state of the tools, here all tools are active and bound to
  // Different mouse inputs
  toolGroup.setToolEnabled(SegmentationDisplayTool.toolName);
  if (useBrush) {
    toolGroup.setToolActive(BrushTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click
        },
      ],
    });
  }
  else
  {
    toolGroup.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click
        },
      ],
    });
  }
  toolGroup.setToolActive(PanTool.toolName, {
    bindings: [
      {
        mouseButton: MouseBindings.Auxiliary, // Middle Click
      },
    ],
  });
  
  toolGroup.setToolActive(ZoomTool.toolName, {
    bindings: [
      {
        mouseButton: MouseBindings.Secondary, // Right Click
      },
    ],
  });
  toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
  
}

const customMetaDataProvider = (type, imageId) => {
  const slice = 0;
  const meta = {
      imagePixelModule: {
          pixelRepresentation: 1,
          bitsAllocated: 8,
          sizeInBytes: 1,
      },
      PhotometricInterpretation : 'MONOCHROME2',
      generalSeriesModule: {
          modality: 'CR',
      },
      imagePlaneModule: {
          imagePositionPatient: slice,
      }
  }

  if (type in meta) {
      return meta[type];
  }
}
export const setupViewports = (setViewports, type) => {
  var canvas = document.getElementById("cornerstone-canvas");

  if (type == "simple") {
    setupSimpleViewport(canvas, setViewports);
  }
  else if (type == "threeway") {
    setupThreeViewport(canvas, setViewports);
  }
  else
  {
    throw new Error('Unsupported image type: ' + type)
  }
}

export function showNextImage(zipFile, viewportType, volumeIds, useBrush=false) {
  naesImageVolumeLoader.setZipFile(zipFile);
  naesNIFTIVolumeLoader.setZipFile(zipFile);
  naesDICOMVolumeLoader.setZipFile(zipFile);
  
  loadAndViewImage(viewportType, volumeIds, useBrush);
}

async function setupSimpleViewport(content, setViewports) {
  if (!content) {
    setViewports(false);
    return;
  } 

  if ((document.getElementById("element_ax")))
    {
      setViewports(true);
      return;
    }

  const viewportGrid = document.createElement('div');
  viewportGrid.style.display = 'flex';
  viewportGrid.style.flexDirection = 'row';
  viewportGrid.style.justifyContent = 'center';

  // element for axial view
  const element = document.createElement('div');
  element.style.width = '30%';
  element.style.height = '500px';
  element.id = 'element_ax';

  viewportGrid.appendChild(element);
  content.appendChild(viewportGrid);

  setViewports(true);
}

function setupThreeViewport(content, setViewports) {
  if (!content) 
  {
    setViewports(false);
    return;
  } 

  if ((document.getElementById("element_ax")))
  {
    setViewports(true);
    return;
  }

  const viewportGrid = document.createElement('div');
  viewportGrid.style.display = 'flex';
  viewportGrid.style.flexDirection = 'row';
  viewportGrid.style.justifyContent = 'space-evenly';

  // element for axial view
  const element1 = document.createElement('div');
  element1.style.width = '30%';
  element1.style.height = '500px';
  element1.id = 'element_ax';

  // element for sagittal view
  const element2 = document.createElement('div');
  element2.style.width = '30%';
  element2.style.height = '500px';
  element2.id = 'element_sag';

  // element for sagittal view
  const element3 = document.createElement('div');
  element3.style.width = '30%';
  element3.style.height = '500px';
  element3.id = 'element_cor';

  viewportGrid.appendChild(element1);
  viewportGrid.appendChild(element2);
  viewportGrid.appendChild(element3);
  content.appendChild(viewportGrid);

  setViewports(true);
}

function isSegmentation(volume) {
  const scalarData = volume.scalarData;
  if (scalarData instanceof Int16Array) {
    return true;
  }
  return false;
}

async function loadAndViewImage(viewportType, volumeIds, useBrush=false) {
  var viewportIds = [];
  const renderingEngineId = 'myRenderingEngine';
  var viewportInput = [];

  if (viewportType === 'threeway') {
    const viewportId1 = 'CT_AXIAL';
    const viewportId2 = 'CT_SAGITTAL';
    const viewportId3 = 'CT_CORONAL';
    
    viewportInput = [
      {
        viewportId: viewportId1,
        element: document.getElementById("element_ax"),
        type: ViewportType.ORTHOGRAPHIC,
        defaultOptions: {
          orientation: Enums.OrientationAxis.AXIAL,
        },
      },
      {
        viewportId: viewportId2,
        element: document.getElementById("element_sag"),
        type: ViewportType.ORTHOGRAPHIC,
        defaultOptions: {
          orientation: Enums.OrientationAxis.SAGITTAL,
        },
      },
      {
        viewportId: viewportId3,
        element: document.getElementById("element_cor"),
        type: ViewportType.ORTHOGRAPHIC,
        defaultOptions: {
          orientation: Enums.OrientationAxis.CORONAL,
        },
      },
    ];

    viewportIds = [viewportId1, viewportId2, viewportId3];
  }
  else if (viewportType === "simple")
  {
    const viewportId = 'CT_AXIAL';
    viewportInput = [
      {
        viewportId: viewportId,
        element: document.getElementById("element_ax"),
        type: ViewportType.ORTHOGRAPHIC,
        defaultOptions: {
          orientation: Enums.OrientationAxis.AXIAL,
        },
      }
    ];
    viewportIds = [viewportId];
  }
  else if (viewportType == "none") {}
  else
  {
    throw new Error('Unsupported viewport type: ' + viewportType);
  }

  var renderingEngine = cornerstone.getRenderingEngine(renderingEngineId);
  if (!renderingEngine) {
    renderingEngine = new RenderingEngine(renderingEngineId);
  }

  for (let seg of cornerstoneTools.segmentation.state.getSegmentations()) {
    cornerstoneTools.segmentation.removeSegmentationsFromToolGroup(toolGroupId);
    cornerstoneTools.segmentation.state.removeSegmentation(seg.segmentationId);
  }
  cornerstone.cache.purgeCache();
  renderingEngine.setViewports(viewportInput);
  
  var volumes = [];
  var loadVolumes = [];
  volumeIds.forEach((volumeId, index) => {
    volumes.push({ volumeId: volumeId,
      callback: setCtTransferFunctionForVolumeActor,
    })
    // if (volumeIds.length == 1) { volumes.push({ volumeId: volumeId })}
    // else if (index == 0) volumes.push({ volumeId: volumeId, opacity: 0.5 });
    // else { volumes.push({ volumeId: volumeId, opacity: 0.5, blendMode: 'AVERAGE' }) }
    loadVolumes.push(cornerstone.volumeLoader.createAndCacheVolume(volumeId));
  })

  await Promise.all(loadVolumes);

  for (let i = 0; i < volumeIds.length; i++) {
    const referenceVolume = cornerstone.cache.getVolume(volumeIds[i]);
    if (!isSegmentation(referenceVolume))
      continue;

    const scalarData = referenceVolume.scalarData;
    const segmentationId = 'seg_' + volumeIds[i];
    await cornerstone.volumeLoader.createAndCacheDerivedSegmentationVolume(volumeIds[i], {
      volumeId: segmentationId,
    });
  
    cornerstoneTools.segmentation.addSegmentations([
      {
        segmentationId,
        representation: {
          type: csToolsEnums.SegmentationRepresentations.Labelmap,
          data: {
            volumeId: segmentationId,
          },
        },
      },
    ]);
  
    const segmentationVolume = cornerstone.cache.getVolume(segmentationId)
    const segmentationScalarData = segmentationVolume.scalarData;
    
    for (let j = 0; j < segmentationScalarData.length; j++) {
      segmentationScalarData[j] = scalarData[j];
    }

    await cornerstoneTools.segmentation.addSegmentationRepresentations(toolGroupId, [
      {
        segmentationId,
        type: csToolsEnums.SegmentationRepresentations.Labelmap,
      },
    ]);

    volumes.splice(i, 1);
  }
  
  await cornerstone.setVolumesForViewports(
    renderingEngine,
    volumes,
    viewportIds
  );
  renderingEngine.renderViewports(viewportIds);
  // for (let viewportId of viewportIds) {
  //   const viewport = renderingEngine.getViewport(viewportId);
  //   viewport.setProperties({ opacity: 0.5 });
  //   viewport.render();
  // }
  
  
  const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
  if (toolGroup)
  {
    for (let viewportId of viewportIds)
    {
      toolGroup.addViewport(viewportId, renderingEngineId)
    }
  }
}

