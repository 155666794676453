import React, { useState, useEffect, useRef } from 'react';
import { Typography, Fade, Card } from '@mui/material';
import { StyledCard } from '../styles';
import './FlipClock.css'; // Custom CSS for any additional styling

const FlipDigit = ({ digit, previousDigit }) => {
  const digitRef = useRef(null);

  useEffect(() => {
    // Flip only if the digit changes
    if (digit !== previousDigit) {
      const flipElement = digitRef.current;

      // Ensure Flip is defined in the global scope
      if (flipElement && window.Flip) {
        flipElement.textContent = digit; // Set the new digit
        window.Flip(flipElement); // Call the Flip function from flip.min.js
      }
    }
  }, [digit, previousDigit]);

  return (
    <span className="flip-digit" ref={digitRef}>
      {digit}
    </span>
  );
};

const FlipClock = ({ value }) => {
  // Convert the value to a string to split into digits
  const digits = value.toString().split('');

  return (
    <div className="flip-clock">
      {digits.map((digit, index) => (
        <FlipDigit
          key={index}
          digit={digit}
          previousDigit={index > 0 ? digits[index - 1] : null} // Pass the previous digit for flipping
        />
      ))}
    </div>
  );
};

const CounterCard = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Start time: August 16, 2024, 00:00 UTC
    const startTime = new Date('2024-08-16T00:00:00Z').getTime();
    const rate = 185.2; // The rate at which the counter increments

    const updateCounter = () => {
      const currentTime = new Date().getTime();
      const timeDifference = Math.floor((currentTime - startTime) / 1000); // in seconds
      setCounter(Math.floor(timeDifference / rate)); // Increment counter based on rate
    };

    // Update immediately on mount
    updateCounter();

    // Set interval to update every second
    const intervalId = setInterval(updateCounter, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <StyledCard >
      <Fade in={true} timeout={2000}>
        <div>
          <Typography variant="h5" gutterBottom>
            Let's make every second count
          </Typography>
          <Typography variant="h7" gutterBottom>
            Total number of evaluations collected so far on naesView:
          </Typography>
          <FlipClock value={counter} />
        </div>
      </Fade>
    </StyledCard>
  );
};

export default CounterCard;
