import React, { useEffect, useRef, useState } from 'react';
import { CardContent, Button, Typography, TextField } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { reviewPost, getPost } from '../../../actions/posts';
import { loadCases, fetchVolumeIds } from '../../../cornerstone/utils';
import { initDemo, setupViewports, showNextImage } from '../../../cornerstone/nifti';
import { fetchQueue, getLabelText, exitFullScreen, enterFullScreen } from '../../../cornerstone/utils';
import { StyledCard, StyledWorkspace, StyledButton, StyledFullScreen, StyledDetails, StyledCanvas, StyledTagsContainer, StyledLabelContainer, StyledLabelPrefix, StyledLabelTextBox, StyledCardContent } from './styles';

const Post = () => {
    const postRef = useRef();
    const [ cases, setCases ] = useState(null);
    const [ zip, setZip ] = useState(null);
    const [ commentValue, setCommentValue ] = useState('');
    const [ viewports, setViewports ] = useState(null);
    const [ volumeIds, setVolumeIds ] = useState({});
    const [ fileQueue, setFileQueue ] = useState({ previous: [], current: null, next: [] });
    const [ reviewDone, setReviewDone ] = useState(false);
    const [ isFullScreen, setIsFullScreen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const { id } = useParams();
    const currentId = id;
    const post = useSelector(state => state.posts.find((p) => p._id === currentId));
    var startTime = new Date();

    useEffect(() => {
        dispatch(getPost(id));
      }, [id]);
    
    useEffect(() => {
        if (post) {
            initDemo(post.useBrush);
            setupViewports(setViewports, post.viewportType);
        }
    }, [post, viewports]);

    useEffect(() => {
        if (!post || !cases)
            return;

        if (!fileQueue.current)
        {
            if (fileQueue.next.length > 0)
                handleNext();
            else
            {
                const queue = fetchQueue(cases, post.reviews);
                if (queue.length > 0)
                {
                    setFileQueue({ previous: [], current: null, next: queue })
                    handleNext();
                }
                else
                {
                    setReviewDone(true);
                    setIsLoading(false)
                }
            }
            return; 
        }

        if (fileQueue.current && Object.keys(volumeIds).length > 0 && volumeIds[fileQueue.current].length > 0)
        {
            setIsLoading(false);
            showNextImage(zip, post.viewportType, volumeIds[fileQueue.current], post.useBrush);
            startTime = new Date();
        }
    

    }, [volumeIds, viewports, fileQueue]);

    const handlePrevious = () => {
        if (fileQueue.previous.length > 0) {
            setReviewDone(false);
            const previousFile = fileQueue.previous.pop();
            if (fileQueue.current)
                setFileQueue({ previous: fileQueue.previous, current: previousFile, next: [fileQueue.current, ...fileQueue.next] });
            else
                setFileQueue({ previous: fileQueue.previous, current: previousFile, next: fileQueue.next });
        }
    }

    const handleNext = () => {
        if (fileQueue.next.length > 0) {
            const nextFile = fileQueue.next.shift();
            if (fileQueue.current)
                setFileQueue({ previous: [...fileQueue.previous, fileQueue.current], current: nextFile, next: fileQueue.next });
            else
                setFileQueue({ previous: fileQueue.previous, current: nextFile, next: fileQueue.next });
        }
        else if (fileQueue.previous.length > 0)
            {
                setReviewDone(true);
                setIsLoading(false)
            }
    }


    const handleFeedback = (button) => {
        const time = Math.floor((new Date() - startTime) / 1000);
        const seg = null;
        if (post.useBrush)
        {
            // seg = getSegmentation();
        }
        dispatch(reviewPost(post._id, { userID: user.id, fileID: cases.indexOf(fileQueue.current), review: button.caption, comment: commentValue, time: time, segmentation: seg }));
        setCommentValue('');
        handleNext();
    }

    const handleFileSelect = async (event) => {
        setZip(event.target.files[0]); 
    }

    useEffect(() => {
        if (zip && !cases)
        {
            setIsLoading(true)
            loadCases(zip, setCases, toast);
        }
        if (cases && zip)
        {
            fetchVolumeIds(zip, cases, setVolumeIds);
        }
    }, [cases, zip]);

    return (
        <>
        <StyledCard>
            <ToastContainer />
            {(reviewDone) && 
                <CardContent>
                    <Typography variant="h5" gutterBottom>naes job! All images have been reviewed!</Typography>
                </CardContent>
            }
            <div>
                { !cases && <input type="file" multiple={false} onChange={(event) => { handleFileSelect(event) }} /> }
            </div>
            <StyledWorkspace ref={postRef} style={{ display: reviewDone ? 'none' : 'block' }}>
                <StyledFullScreen >
                    <Button onClick={isFullScreen ? null : enterFullScreen(postRef.current, setIsFullScreen)}><FullscreenRoundedIcon /></Button>
                    <Button onClick={isFullScreen ? exitFullScreen(document, setIsFullScreen) : null}><FullscreenExitRoundedIcon /></Button>
                </StyledFullScreen>
                <StyledDetails>
                    {(fileQueue.current && post) && post.labels.map((label, index) => {
                        const labelText = getLabelText(fileQueue.current, label.delimiter, label.index);
                        return (
                            <StyledLabelContainer key={index}>
                                <StyledLabelPrefix>{label.prefix}</StyledLabelPrefix>
                                <StyledLabelTextBox>{labelText}</StyledLabelTextBox>
                            </StyledLabelContainer>
                        );
                    })}
                </StyledDetails>
                <StyledCanvas style={{ display: fileQueue.current ? 'block' : 'none' }} onContextMenu={(e) => e.preventDefault()} id="cornerstone-canvas"/>
                <StyledTagsContainer>
                    <StyledButton><Button variant="contained" disabled={fileQueue.previous.length == 0} onClick={() => { handlePrevious() }}> 
                        <ArrowBackIosRoundedIcon />
                    </Button></StyledButton>
                    {post && post.buttons.map((button, index) => (
                        <StyledButton><Button key={index} disabled={!fileQueue.current} variant="contained" onClick={() => { handleFeedback(button) }}> 
                            {button.caption}
                        </Button></StyledButton>
                    ))}
                    <StyledButton><Button variant="contained" disabled={fileQueue.next.length == 0} onClick={() => { handleNext() }}> 
                        <ArrowForwardIosRoundedIcon />
                    </Button></StyledButton>
                </StyledTagsContainer>
                <StyledTagsContainer>
                    {(post && post.useComments) && <TextField
                            variant="outlined"
                            label={`Comments (optional)`}
                            fullWidth
                            disabled={!fileQueue.current}
                            value={commentValue}
                            onChange={(e) => { setCommentValue(e.target.value) }}
                        />}
                </StyledTagsContainer>
            </StyledWorkspace>
            {post && <StyledCardContent>
                <Typography variant="h4" gutterBottom>{post.title}</Typography>
                <Typography variant="h7" gutterBottom>{post.description}</Typography><br/>
                <Typography sx={{fontStyle: 'italic'}} variant="h9" gutterBottom>Created by {post.name} ({moment(post.createdAt).fromNow()})</Typography>
                <Typography variant="h8" gutterBottom>Reviews so far: {post.reviews.length}</Typography>
            </StyledCardContent>
            }
            
        </StyledCard>

        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => { setIsLoading(false) }}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
        </>
        )};

export default Post;