import React, { useState } from 'react';
import { Typography } from '@mui/material';

import { StyledCard, StyledBox, StyledTabs, StyledTab } from './styles';

// Utility function for a11y props
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

// CustomTabPanel Component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBox>
          {children}
        </StyledBox>
      )}
    </div>
  );
}

const Examples = () => {
  const [primaryTab, setPrimaryTab] = useState(0);
  const [secondaryTab, setSecondaryTab] = useState(0);

  const handlePrimaryChange = (event, newValue) => {
    setPrimaryTab(newValue);
  };

  const handleSecondaryChange = (event, newValue) => {
    setSecondaryTab(newValue);
  };

  const secondaryTabs = ["Stack", "Side-by-side stacks", "Fused stacks"];

  return (
    <StyledCard>
      <div>
      <Typography variant="h5" gutterBottom style={{ paddingLeft: "24px" }}>Examples</Typography>
      <StyledBox>
        <StyledBox>
          <StyledTabs centered value={primaryTab} onChange={handlePrimaryChange} aria-label="primary tabs example">
            <StyledTab label="JPG" {...a11yProps(0)} />
            <StyledTab label="DICOM" {...a11yProps(1)} />
            <StyledTab label="NIFTI" {...a11yProps(2)} />
          </StyledTabs>
        </StyledBox>
        <CustomTabPanel value={primaryTab} index={0}>
          <StyledBox>
            <StyledTabs centered value={secondaryTab} onChange={handleSecondaryChange} aria-label="secondary tabs example">
              {secondaryTabs.map((label, index) => (
                <StyledTab key={index} label={label} {...a11yProps(index)} />
              ))}
            </StyledTabs>
          </StyledBox>
          <CustomTabPanel value={secondaryTab} index={0}>
            <img src="https://naesview.com/examples/j1.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/1.png" alt="example" width="30%" style={{margin: "0px 0px 0px 15%"}}/>
          </CustomTabPanel>
          <CustomTabPanel value={secondaryTab} index={1}>
            <img src="https://naesview.com/examples/j2.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/2.png" alt="example" width="60%" style={{margin: "0px 0px 0px 0px"}}/>
          </CustomTabPanel>
          <CustomTabPanel value={secondaryTab} index={2}>
            <img src="https://naesview.com/examples/n3.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/3.png" alt="example" width="30%" style={{margin: "0px 0px 0px 15%"}}/>
          </CustomTabPanel>
        </CustomTabPanel>
        <CustomTabPanel value={primaryTab} index={1}>
          <StyledBox>
            <StyledTabs centered value={secondaryTab} onChange={handleSecondaryChange} aria-label="secondary tabs example">
              {secondaryTabs.map((label, index) => (
                <StyledTab key={index} label={label} {...a11yProps(index)} />
              ))}
            </StyledTabs>
          </StyledBox>
          <CustomTabPanel value={secondaryTab} index={0}>
            <img src="https://naesview.com/examples/d1.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/1.png" alt="example" width="30%" style={{margin: "0px 0px 0px 15%"}}/>
          </CustomTabPanel>
          <CustomTabPanel value={secondaryTab} index={1}>
            <img src="https://naesview.com/examples/d2.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/2.png" alt="example" width="60%" style={{margin: "0px 0px 0px 0px"}}/>
          </CustomTabPanel>
          <CustomTabPanel value={secondaryTab} index={2}>
            <img src="https://naesview.com/examples/d3.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/3.png" alt="example" width="30%" style={{margin: "0px 0px 0px 15%"}}/>
          </CustomTabPanel>
        </CustomTabPanel>
        <CustomTabPanel value={primaryTab} index={2}>
          <StyledBox>
            <StyledTabs centered value={secondaryTab} onChange={handleSecondaryChange} aria-label="secondary tabs example">
              {secondaryTabs.map((label, index) => (
                <StyledTab key={index} label={label} {...a11yProps(index)} />
              ))}
            </StyledTabs>
          </StyledBox>
          <CustomTabPanel value={secondaryTab} index={0}>
            <img src="https://naesview.com/examples/n1.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/1.png" alt="example" width="30%" style={{margin: "0px 0px 0px 15%"}}/>
          </CustomTabPanel>
          <CustomTabPanel value={secondaryTab} index={1}>
            <img src="https://naesview.com/examples/n2.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/2.png" alt="example" width="60%" style={{margin: "0px 0px 0px 0px"}}/>
          </CustomTabPanel>
          <CustomTabPanel value={secondaryTab} index={2}>
            <img src="https://naesview.com/examples/n3.png" alt="example" width="30%" style={{margin: "0px 0px 0px 5%"}}/>
            <img src="https://naesview.com/examples/3.png" alt="example" width="30%" style={{margin: "0px 0px 0px 15%"}}/>
          </CustomTabPanel>
        </CustomTabPanel>
      </StyledBox>
      </div>
    </StyledCard>
  );
}

export default Examples;