import { styled } from '@mui/material/styles';
import { Card, Box, Tabs, Tab } from '@mui/material';

// Define styled components
export const StyledCard = styled(Card)(({ theme }) => ({
    margin: theme.spacing(2),
    display: 'flex',
    padding: theme.spacing(3, 0, 0, 0),
    height: '58vh',
    width: '100%',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%', 
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(0),
    // borderBottom: 1, 
    // borderColor: 'divider'
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    // borderBottom: 1,
    // borderColor: 'divider',
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    // borderBottom: 1,
    // borderColor: 'divider',
}));
